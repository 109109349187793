import ManageUserCard from "components/ManageUserCard";
import { graphql } from "generated";
import useDocumentTitle from "lib/useDocumentTitle";
import { DateTime } from "luxon";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "urql";

const GetProjectAndSessionAttendanceRecordsDocument = graphql(`
    query GetProjectAndSessionAttendanceRecords($projectId: ID!, $projectSessionId: ID!) {
        project(id: $projectId) {
            id
            name
            session(id: $projectSessionId) {
                id
                name
                attendanceRecords {
                    id
                    timestamp
                    type
                    user {
                        ...ManageUserCard_UserFragment
                    }
                }
            }
        }
    }
`)

export default function AdminProjectAttendanceHistoryPage() {
    useDocumentTitle("Admin Project Attendance History | AIAA@UCF Members")
    const { projectId, projectSessionId } = useParams()
    const [result] = useQuery({ query: GetProjectAndSessionAttendanceRecordsDocument, variables: { projectId: projectId!, projectSessionId: projectSessionId! } })

    if (result.data?.project?.session == null) {
        throw new Response("Not Found", { status: 404 })
    }

    return (
        <div>
            {result.data.project.session.attendanceRecords?.map(ar => ar.user != null && (
                <ManageUserCard user={ar.user} extra={DateTime.fromSeconds(ar.timestamp).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) + " by " + ar.type} baseComponent={LinkBaseComponent} />
            ))}
        </div>
    )
}

const LinkBaseComponent = (props: {
    className: string
    children?: React.ReactNode
    userId: string
}) => <Link to={`/admin/users/${props.userId}`} {...props} />