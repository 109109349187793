import { ResultOf } from "@graphql-typed-document-node/core";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { ButtonPrimary } from "components/Button";
import ProfilePicture from "components/ProfilePicture";
import { getFragmentData, graphql } from "generated";
import useDocumentTitle from "lib/useDocumentTitle";
import { useEffect, useState } from "react";
import { useClient } from "urql";

const SubmitUserJwtForGbmDocument = graphql(`
    mutation SubmitUserJwtForGbm($jwtCode: String!) {
        submitUserJwtForTemp_GBM_4_18_2024(jwtCode: $jwtCode) {
            ...AdminGbmAdminscannerPage_ACPScanResultFragment
        }
    }
`)

const AdminGbmAdminscannerPage_ACPScanResultFragment = graphql(`
    fragment AdminGbmAdminscannerPage_ACPScanResultFragment on ACPScanResult {
        __typename
        ... on ACPScanResultSuccess {
            timestamp
            user {
                id
                fullName
                membership {
                    __typename
                }
                hasDemographics

                ...ProfilePicture_UserFragment
            }
        }
        ... on ACPScanResultFail {
            reason
        }
    }
`)

let lastScannedString = ""

export default function AdminGbmAdminscannerPage() {
    useDocumentTitle("ACP Scanner | AIAA@UCF Members")
    const client = useClient()
    const [currentResult, setCurrentResult] = useState<ResultOf<typeof AdminGbmAdminscannerPage_ACPScanResultFragment> | null | undefined>(null)

    const onScan = async (data: string) => {
        if (data === lastScannedString) return
        lastScannedString = data
        const result = await client.mutation(SubmitUserJwtForGbmDocument, { jwtCode: data })
        const resultData = getFragmentData(AdminGbmAdminscannerPage_ACPScanResultFragment, result.data?.submitUserJwtForTemp_GBM_4_18_2024)
        setCurrentResult(resultData)
    }

    useEffect(() => {
        if (currentResult?.__typename === "ACPScanResultSuccess") {
            const timeout = setTimeout(() => setCurrentResult(null), 5000)
            return () => {
                clearTimeout(timeout)
                lastScannedString = ""
            }
        }
    }, [currentResult, setCurrentResult])

    return (
        <div className={`w-screen h-screen text-white ${currentResult == null && "bg-black"} ${currentResult?.__typename === "ACPScanResultFail" && "bg-red-500"} ${currentResult?.__typename === "ACPScanResultSuccess" && "bg-green-500"}`}>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            {currentResult?.__typename !== "ACPScanResultFail" && <QrScanner onError={() => { }} onDecode={onScan} />}
            <div className="flex justify-center items-center pt-6">
                {currentResult == null && (
                    <h3 className="text-3xl">Ready to scan</h3>
                )}
                {currentResult?.__typename === "ACPScanResultSuccess" && currentResult.user != null && (
                    <div className="flex">
                        <ProfilePicture user={currentResult.user} size={64} />
                        <div className="flex flex-col items-center ml-4">
                            <span className="text-xl">{currentResult.user.fullName}</span>
                            {currentResult.user.membership != null && (
                                <div className="rounded-lg bg-green-600 py-1.5 px-3 text-xs font-bold uppercase text-white">
                                    Has Active Membership
                                </div>
                            )}
                            {currentResult.user.membership == null && (
                                <div className="rounded-lg bg-red-600 py-1.5 px-3 text-xs font-bold uppercase text-white">
                                    No Active Membership
                                </div>
                            )}
                            {currentResult.user.hasDemographics === true && (
                                <div className="rounded-lg bg-green-600 my-1 py-1.5 px-3 text-xs font-bold uppercase text-white">
                                    Has Demographics
                                </div>
                            )}
                            {currentResult.user.hasDemographics === false && (
                                <div className="rounded-lg bg-red-600 my-1 py-1.5 px-3 text-xs font-bold uppercase text-white">
                                    Needs Demographics
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {currentResult?.__typename === "ACPScanResultFail" && (
                    <div className="flex flex-col items-center justify-center mt-32">
                        <h3 className="text-4xl">SCAN FAIL</h3>
                        <p className="text-2xl px-4 text-center">{currentResult.reason}</p>
                        <ButtonPrimary onClick={() => { setCurrentResult(null); lastScannedString = "" }}>DENY ACCESS</ButtonPrimary>
                    </div>
                )}
            </div>
        </div>
    )
}